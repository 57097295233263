@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.container {
    background-color: rgba(207, 210, 211, 0.16);
    width: 100%;
    padding-top: 10px;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    overflow: hidden;
    display: flex;
}

.main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* No necesitas flex-grow aquí si estás definiendo el ancho de los hijos */
}

.formador-card-container {
    display: flex;
    background-color: #fff;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    gap: 1rem;
    width: 96.5%;
}

.grid-container {
    width: 100%;
    margin-top: 1.5rem;
    display: inline-block;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: calc(100vh - 270px);
    overflow-x: hidden;
    overflow-y: auto;
}

.card {
    max-height: 100%;
    display: flow;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 1rem;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}

.cardColumn {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    /* Cada columna ocupa un 25% del ancho de la tarjeta */
    max-height: 220px;
    overflow-y: auto;
}

.cardColumn_noData {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    max-height: 220px;
    overflow-y: hidden;
}


.flexRowLeftAlign {
    margin: 5px 10px;
}

.sidebar {
    width: 23%;
    text-align: left;
    margin-top: 16px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 1rem;
    max-height: 50vh;
    padding-bottom: 16%;
    overflow-y: auto;
}

.actividades,
.experiencia {
    width: 100%;
}

.actividadesHeader {
    font-size: 23px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

.experienciaHeader {
    font-size: 23px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

.circuloColor {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    align-self: center;
}

.actividadesList {
    list-style: none;
    padding-left: 0;
}

.actividadItem {
    font-size: 14px;
    padding-bottom: 5px;
}

.actividadItem::before {
    content: none;
}

.actividadColor {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.experienciasList {
    list-style: none;
    padding-left: 0;
}

.experienciaItem {
    font-size: 14px;
    padding-bottom: 5px;
}

.experienciaItem::before {
    content: none;
}

/* Personaliza la barra de desplazamiento */
.sidebar::-webkit-scrollbar {
    width: 10px;
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #888;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.flexColumnLeft {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    gap: 12px;
}

.pBoldBlack {
    margin: 0;
    color: #363C3F;
    font-weight: 700;
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    line-height: 25.87px;
}

.pItemActividad {
    color: black;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    line-height: 21.79px;

}

.pItemHora {
    color: black;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    line-height: 19.07px;
}

.sub-container {
    width: 70%;
    display: inline-block;
    flex-direction: row;
    padding-left: 24px;
    padding-top: 0;
    padding-right: 24px;
    justify-content: space-between;
}

.fecha-container {
    display: inline-flex;
    width: 35%;
}

.modal-backdrop {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.flexRowCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    width: 38%;
}

.flexRowCenter-Left {
    flex-direction: row;
    align-items: center;
    gap: 24px;
    width: 18%;
    justify-content: flex-end;
}

.select-week-button {
    margin-top: 15px !important;
}

.imgPointer {
    display: flex;
    align-items: center;
    gap: 6px;
    object-fit: contain;
    cursor: pointer;
    font-weight: 700;
    color: #7F888D;
}

.values {
    color: #5E6A71;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    font-family: 'Open Sans', sans-serif;
}

.formador {
    color: #006990;
    font-weight: 700;
    font-size: 14px;
    line-height: 19.07px;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.scrollable {
    max-height: 500px;
    overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
    width: 9px;

}

/* Estiliza el "track" de la barra de desplazamiento */
.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Estiliza el "thumb" de la barra de desplazamiento */
.scrollable::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}