.header-container {
  display: flex;
  height: 10vh;
  background-color: #363c3f;
  justify-content: space-between;
  gap: 8px;
}

.logo-container {
  display: flex;
  padding: 15px;
}

.logo {
  width: 145px;
  height: 35px;
  align-self: center;
}

.collapsing-arrows {
  width: 15px;
  height: 15px;
  margin-left: 50px;
  align-self: flex-end;
}

.collapsing-arrows:hover {
  cursor: pointer;
}

.header-container h1 {
  margin: 0;
  align-self: center;
  color: #ffffff;
}

.profile-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer; flex-wrap: wrap;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; 
  max-width: 60%;
}

.tooltip {
  background-color: #006990 !important;
  opacity: 100 !important;
  width: 258px !important;
  z-index: 9999 !important;
}

.header-title {
  font-family: 'Bree Serif';
  font-weight: 400;
  font-size: 1.4rem;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que se desborda */
  text-overflow: ellipsis;
  max-width: 100%;
}

.user-name {
  text-transform: capitalize;
  color: #ffffff;
  margin-right: 20px;
  align-self: center;
  width: 120px;
}

.user-name:hover {
  cursor: pointer;
}

.notification-bell-container {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.user-menu {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px; /* Bordes semi redondos */
  z-index: 10 !important; /* Asegurar que el menú esté por encima del contenido del fondo */
}

.user-menu img {
  vertical-align: middle;
  border-radius: 50%;
}

.user-name {
  position: relative;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #363c3f;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Asegurar que el submenu esté por encima del contenido del fondo */
  border-radius: 0 0 10px 10px; /* Bordes semi redondos solo en la parte inferior */
  border-top-left-radius: 0; /* Borde superior izquierdo recto */
  border-top-right-radius: 0; /* Borde superior derecho recto */
}

.user-menu:hover .submenu {
  display: block;
}

.submenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.submenu ul li {
  padding: 10px;
  cursor: pointer;
  color: #ffffff; /* Cambio de color a morado */
  border-radius: 20px; /* Bordes semi redondos en el submenu */
}

.submenu ul li:hover {
  color: #a346dd; /* Texto blanco al hacer hover */
}

.upload-message-container {
  position: absolute;
  margin-top: 73px;
  /* Ajusta el valor según sea necesario para que se adapte a tu diseño */
  border-radius: 5px;
  font-weight: 500;
  background-color: #c5f3f1;
  width: 100%;
  padding-bottom: 45px;
  margin-bottom: 24px;
  z-index: 9999999;
}

.upload-message-container.success {
  background-color: #c5f3f1;
}

.upload-message-container.error {
  background-color: #fccfda;
  margin-bottom: 8px; /* Ajusta el margen si es necesario */
  z-index: 9999999;
}

.upload-message-container.loading-data {
  /* Added for info messages */
  background-color: #e1e2e3;
  z-index: 9999999;
}

.grupos-alert {
  bottom: 95px;
  z-index: 9999999;
}

.update-file-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  border-radius: 8px;
  z-index: 9999999;
}

.update-file-message img {
  margin: 10px;
  z-index: 9999999;
}

.update-file-message p {
  margin: 0;
  text-align: left;
  flex-grow: 1;
  color: #5e6a71;
  font-size: 14px;
  z-index: 9999999;
}

.loading-data {
  /* Use this class for info messages */
  background-color: #bdbdbd;
  z-index: 9999999;
}

.closse-alert {
  cursor: pointer;
  margin-right: 44px;
  z-index: 9999999;
}
