.updatedb-modal {
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 3;
}

@media (max-width: 992px) {
  .updatedb-container {
    width: 95%;
  }

  .upload-file-buttons {
    flex-direction: column !important;
    row-gap: 12px;
  }

  .accept-button {
    margin: 0px !important;
  }

  .cancel-button {
    margin: 0px !important;
  }
}

.updatedb-container {
  background-color: #ffffff;
  align-self: center;
  border-radius: 10px;
}

.updatedb-container p {
  font-size: 13px;
  font-weight: bold;
  color: #7f888d;
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.input-container {
  display: flex;
  border: 1px solid #afb4b8;
  padding: 5px;
  margin: 0 20px;
  border-radius: 5px;
  cursor: pointer;
}

.input-container:hover {
  cursor: pointer;
}

.input-container p {
  margin: 1px 0 0 0;
  color: #5e6a71;
  font-weight: normal;
}

.input-container input {
  opacity: 0;
  cursor: pointer;
}

.upload-file {
  display: flex;
  position: absolute;
  cursor: pointer;
  width: 300px;
}
.upload-file p {
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que se desborda */
  text-overflow: ellipsis; /* Muestra los puntos suspensivos */
  margin-left: 10px; /* Espacio entre la imagen y el texto */
  width: 100%; /* Asegúrate de que el párrafo ocupe todo el ancho disponible */
}
.upload-file img {
  margin-right: 10px;
  margin-left: 5px;
  padding: 1px;
  cursor: pointer;
}

.upload-file-buttons {
  display: flex;
  margin-top: 15px;
  padding: 15px;
  justify-content: space-around;
  border-top: 1px solid #e0e1e3;
}

.cancel-button {
  background: #FFFFFF;
  border: 1.5px solid #652D89 !important;
  border-radius: 5px;
  color: #652D89;
  padding: 10px 70px;
  margin-right: 10px;
  width: auto;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.accept-button {
  background: #652D89;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  padding: 10px 70px;
  margin-left: 10px;
  width: auto;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s, colo
}

.accept-button.disabled {
  background: rgba(158, 166, 169, 0.24);
  color: #5E6A71;
  cursor: not-allowed;
}

.cancel-button:hover,
.accept-button:not(.disabled):hover {
  background-color: #7A42A0;
  color: #FFFFFF;
}

.cancel-button:hover,
.accept-button:not(.disabled):hover {
  padding: 10px 70px;
}

.uploading-title {
  font-weight: bold;
  color: #333;
}

.file-name {
  font-weight: normal;
  color: #666;
}

.progress-bar-container {
  width: 92%;
  margin: 1rem;
  border-radius: 5px;
  background-color: #eee;
}

.progress-bar {
  height: 20px;
  text-align: center;
  color: black;
}