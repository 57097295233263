.colabs-filter-modal {
  position: absolute;
  background: #00000040;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  overflow: auto;
  z-index: 30;
}

.colabs-filter-container {
  background-color: #ffffff;
  align-self: center;
  border-radius: 10px 10px 10px 10px;
  width: 45%;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 576px) {
  .colabs-filter-container-trackAnual {
    width: 95% !important;
    right: auto !important;
  }
  .colabs-filter-container {
    border-radius: 0;

    width: 100%;

  }
}

.colabs-filter-container-trackAnual {
  text-align: center;
  background-color: #ffffff;
  align-self: center;
  border-radius: 10px 10px 10px 10px;
  width: 60%;
  position: absolute;
  top: 10%;
  right: auto;
}

.colabs-filter-header {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #e0e1e3;
  justify-content: space-between;
}

.colabs-filter-header p {
  font-size: 19px;
  font-weight: bold;
  color: #363c3f;
  margin: 0;
}

.colabs-filter-header img {
  align-self: center;
  cursor: pointer;
}

p.colabs-filter-option {
  margin: 0 0 15px 15px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #363c3f;
}

.fecha-contratacion {
  margin: 10px 0;
}

.date-picker-container {
  position: relative;
  text-align: left;
  margin-left: 15px;
}

p.label-title {
  position: absolute;
  bottom: 35px;
  margin: 0 0 0 25px;
  font-size: 13px;
  font-weight: normal;
  color: #bfc3c6;
  background: white;
}

p.date-picker {
  border: 1px solid #afb4b8;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  color: #5e6a71;
  padding: 10px;
  margin: 0 15px;
  border-radius: 4px;
}

.checkbox-option {
  display: flex;
  align-items: center;
  margin: 0 0 20px 12px;
}

.checkbox-option label {
  display: flex;
  width: 30%;
  text-align: left;
  margin-right: 25px;
}

.checkbox-option label input {
  margin-right: 5px;
}

input[type='checkbox'] {
  accent-color: #652d89;
  height: 16px;
  width: 16px;
  margin: 0 5px 0 0;
  align-self: center;
}

.cancel-button {
  border: none;
  text-decoration: underline;
  color: #7f888d;
}

@supports (accent-color: #fff) {
  .info {
    display: none;
  }
}

.checkbox {
  display: flex;
  flex-grow: 1;
  width: 30%;
  align-self: center;
  text-align: left;
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 15px;
}

.checkbox-container label {
  display: flex;
}

.MuiAutocomplete-option:hover {
  background-color: #652d89 !important; 
  color: #ffffff; 
}