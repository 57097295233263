.sombra-specs-body {
  background-image: url('../../assets/transparentFlower.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  * {
    font-family: 'Open Sans', sans-serif;

  }

  .sombra-inform {

    @media (max-width: 767.98px) {
      display: none;
    }

    .banner {
      background-image: url('../../assets/pdfSombraBanner.svg');
      width: 100%;
      height: 100px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    h1 {
      text-align: center;
      padding-top: 0;
      color: #363c3f;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1px;
      margin: 12px 0;
    }

    h3 {
      text-align: center;
      color: #006990;
      margin: 0;
      font-family: 'Open Sans', sans-serif;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.8px;
      margin-bottom: 24px;
    }
  }

  .dvider {
    width: 100%;
    height: 2px;
    border-bottom: 3px solid #006990;

    &.light {
      border-bottom: 1px solid #00ae9e;
    }
  }

  .pdf-sombra-download-button {
    background-color: #652d89;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 12px;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    position: sticky;
    top: 20px;
    z-index: 2;
    margin-bottom: 24px;
    @media (max-width: 767.98px) {
      margin:  auto;
    };
    &:disabled{
      background-color: #7e658e;
      cursor: not-allowed;

    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .row {
      display: flex;
      width: 100%;
      gap: 12px;

      .col-title {
        color: #000;
        font-family: 'Open Sans', sans-serif;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      .col {
        width: 100%;

        &.center {
          text-align: center;
        }

        &.center {
          text-align: center;
        }

        &-1 {
          width: 10%;

          &.center {
            text-align: center;
          }
        }

        &-2 {
          width: 20%;

          &.center {
            text-align: center;
          }
        }

        &-3 {
          width: 30%;

          &.center {
            text-align: center;
          }
        }

        &-4 {
          width: 40%;

          &.center {
            text-align: center;
          }
        }

        &-5 {
          width: 50%;

          &.center {
            text-align: center;
          }
        }

        &-6 {
          width: 60%;

          &.center {
            text-align: center;
          }
        }

        &-7 {
          width: 70%;

          &.center {
            text-align: center;
          }
        }

        &-8 {
          width: 80%;

          &.center {
            text-align: center;
          }
        }

        &-9 {
          width: 90%;

          &.center {
            text-align: center;
          }
        }
      }
    }

    .pills {
      color: #000;
      text-align: center;
      font-family: 'Open Sans', sans-serif;

      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.45px;
      border-radius: 12px;
      max-width: 140px;
      min-width: 100px;
      padding: 2px 4px;

      &.green {
        background-color: #9becc0;
      }

      &.yellow {
        background-color: #ece59b;
      }

      &.red {
        background-color: #ecb09c;
      }

      &.dark-green {
        background-color: #00ae9e;
        color: white;
      }

      &.dark-yellow {
        background-color: #f58025;
        color: white;
      }

      &.dark-red {
        background-color: #b1005c;
        color: white;
      }
    }
  }

  .table-one {
    color: #000;
    font-family: 'Open Sans', sans-serif;

    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.55px;
  }

  .table-two {
    margin-top: 40px;

    .card {
      display: flex;
      height: 30px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      align-self: stretch;
      color: #fcfcfc;
      text-align: center;

      font-family: 'Open Sans', sans-serif;

      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.4px;
      border-radius: 8px;

      &.orange {
        background: #f58025;
      }

      &.green {
        background: #00b050;
      }
    }

    .results {
      color: #363c3f;
      text-align: center;
      font-family: 'Open Sans', sans-serif;

      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.65px;
    }
  }

  .table-three {
    margin-top:48px ;
    margin-bottom:124px ;
    h1 {
      text-align: center;
    }

    .tabla-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }

    .tabla-desempeno {
      width: 400px;
      border-collapse: collapse;
      text-align: center;
      font-family: Arial, sans-serif;
      border-radius: 10px;
      overflow: hidden;

      thead {
        background-color: #00a99d;
        color: white;

        th {
          padding: 4px 40px 3px 41px;
          font-size: 18px;
        }
      }

      tbody {
        background-color: #e0e1e3;

        tr {
          td {
            width: 50px;
            padding: 4px;
            color: #5e6a71;
            border: 2px solid #f7f8f8;
            font-family: 'Open Sans', sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.35px;
          }

          &:first-child td {
            border-top: none;
          }
        }
      }
    }
  }

  .table-four {
    .border {
      border-bottom: 3px solid #00ae9e;
    }

    .col-title {
      color: #000;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .results {
      color: #00ae9e;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 1px;
    }
  }

  .table-five {
    .pills {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

@media print {
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}
