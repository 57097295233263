.download-sombra-pdf {
  background-color: #652d89;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  width: auto;
  height: 33px;
  margin-top: 3px;
  padding: 8px;
  color: white;
  border: none;
  &.disabled {
    background-color: #662d899b;
    cursor: not-allowed;
  }
}

/* Variables de color */
$background-color: #fff;
$header-background-color: #006990;
$header-background-color-green: #00ae9e;
$header-background-color-gray: #9ea6a9;

$estado-color: #00b050;
$calificacion-color: #00b050;
$experto-color: #00b050;
$lider-color: #006c8c;
$boton-background-color: #652d89;
$box-shadow-color: rgba(0, 0, 0, 0.1);

.level-cards-container {
  position: relative;
  .level-container {
    position: relative;
    /*     background-color: red; */
    padding: 24px 0;
    display: flex;
    gap: 18px;
    align-items: center;
    justify-content: center;
    .nivel-card {
      width: 400px;
      background-color: $background-color;
      border-radius: 16px;
      box-shadow: 0px 2px 10px $box-shadow-color;
      font-family: Arial, sans-serif;
      overflow: hidden;
      &.green {
        & .nivel-card-header {
          background-color: $header-background-color-green;
        }
        .step-icon {
          color: $header-background-color-green;
        }
      }
      &.gray {
        & .nivel-card-header {
          background-color: $header-background-color-gray;
        }
        .step-icon {
          color: $header-background-color-gray;
        }
      }
      * {
        font-family: 'Open Sans', sans-serif;
        font-size: 11px;
        font-weight: 700;
        line-height: 14.98px;
        letter-spacing: 0.05em;
      }
      span {
        text-align: left;
      }
      p {
        color: #363c3f;
      }
      &-header {
        cursor: pointer;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $header-background-color;
        color: $background-color;
        border-radius: 16px;
        padding: 8px;
        padding-left: 16px;
        height: 48px;
        display: flex;
        transition: border-radius 0.4s ease;
        svg {
          transition: transform 0.4s ease;
        }
        &.isOpen {
          border-radius: 16px 16px 0 0;
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &-content {
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition:
          max-height 0.4s ease,
          opacity 0.4s ease;
        &.isOpen {
          padding: 8px 16px 16px 16px;
        }
      }

      &-estado {
      
        background-color: $estado-color;
        color: $background-color;
        padding: 6px 12px;
        border-radius: 16px;
        min-width: 58px;
        max-width: 160px;
        width: fit-content;
        font-size: 10px;
        font-weight: 700;
        line-height: 13.62px;
        letter-spacing: 0.05em;
        text-align: center;
        &.error{
          background: #ff7085;
          color: white;

        }
        &.number {
          font-size: 10px;
          font-weight: 700;
          line-height: 21.79px;
          letter-spacing: 0.05em;
          text-align: center;
          padding: 0;
          &.Completado {
            padding: 0 8px;
          }
          &.Pendiente {
            background: #ffe770;
            color: #363c3f;
            padding: 0 8px;
          }

          &.No.Completado {
            background: #ff7085;
            color: white;
            padding: 0 8px;
          }
        }
      }
      &-porcentaje {
        font-weight: 500;
      }
      &-calificacion {
        text-align: center;

        .calificacion {
          font-size: 1.5rem;
          font-weight: bold;
          color: $calificacion-color;
        }
      }
      &-icono {
        background-color: white;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        color: #006990;
        .step-icon {
          font-size: 24px;
          font-weight: 400;
          font-family: Bree Serif;
          margin: 7px 0;
        }
      }
      &-info {
        background-color: lighten($background-color, 5%);
        border-radius: 8px;
        padding: 8px;
        margin-bottom: 12px;
        width: 100%;

        .info-item {
          display: flex;
          justify-content: space-between;
          padding: 4px 0;
          background-color: #eff0f2;
          margin-bottom: 12px;
          border-radius: 8px;
          padding: 8px;
          .status {
            font-weight: bold;

            &.experto {
              color: $experto-color;
            }

            &.lider {
              color: $lider-color;
            }
          }
        }
      }

      &-boton {
        background-color: $boton-background-color;
        color: $background-color;
        border: none;
        border-radius: 8px;
        width: 100%;
        height: 24px;
        padding: 2px;
        font-weight: bold;
        cursor: pointer;

        @media (max-width: 500px) {
          height: 34px;
        }
        &:disabled {
          background-color: #959595;
          cursor: not-allowed;
        }
      }
    }
  }

  .image-container {
    position: relative;
    img {
      max-height: 64px;
    }
    .trace-container {
      position: absolute;
      z-index: -1;
      top: 5px;
      overflow: hidden;
      left: 50%;
    }
  }
}
