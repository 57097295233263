@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.container {
  background-color: rgba(207, 210, 211, 0.16);
  width: 100%;
  overflow: auto;
}

.scrollable-container {
  max-height: 650px;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 5px;
}

.scrollable-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.container-onboarding {
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: rgba(207, 210, 211, 0.16);

  .container-carousel {
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: white;
    border-radius: 24px;
    width: 90%;
    align-items: center;
    align-content: center;
    margin: auto;
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .text {
    padding-bottom: 28px;
    text-transform: capitalize;
    font-family: 'Bree Serif';

    h1 {
      font-size: 48px;
      margin: 0;
      color: #363c3f;
      font-weight: 400;
    }

    span {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

      font-size: 23px;
      font-weight: 700;
      color: #5e6a71;
    }

    div {
      padding-top: 24px;
      width: 50%;
      margin: auto;

      span {
        font-family: 400;
        font-size: 18px;
      }

      p {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 300;
      }
    }
  }

  .image-gallery-bullets {
    transform: translateY(50px);

    .image-gallery-bullets-container {
      .image-gallery-bullet:hover {
        background-color: white;
        border-color: #652d89;
      }

      .image-gallery-bullet.active {
        background-color: #652d89;
      }
    }
  }
}

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  background: white;
}

.login-elements {
  width: 30%;
  position: relative;
  z-index: 2;
  text-align: center;
}
@media (max-width: 992px) {
  .login-elements {
    width: 80%;

  }
}
.left-banner {
  position: absolute;
  top: 0;
  left: 0;
}

.rigth-banner {
  position: absolute;
  bottom: 0px;
  right: 0;
}

.login-logo {
  width: 62%; /* Cambia el porcentaje según tus necesidades */
  max-width: 400px; /* Establece un ancho máximo para evitar que el logo se vea demasiado grande en pantallas grandes */
  height: auto; /* Esto mantiene la proporción original del logo */
  margin-bottom: 15px; /* Ajusta el margen inferior según tu diseño */
}

.login-title {
  margin: 0;
  color: #5e6a71;
  text-align: left;
  font-size: 33px;
  text-align: center;
}

.login-text {
  text-align: left;
  color: #7f888d;
  font-size: 16px;
  margin-bottom: 30px;
}

.login-inputs {
  display: flex;
  flex-direction: column;
}

.login-inputs div input {
  padding: 7px 7px 7px 30px;
  border: 1px solid #afb4b8;
  border-radius: 4px;
  width: 95%;
}

.login-input {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}

.input-logo {
  align-self: center;
  position: absolute;
  left: 10px;
}

.show-password {
  align-self: center;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.login-inputs input:focus {
  outline: none;
}

.submit-button {
  background-color: #652d89;
  border: none;
  border-radius: 8px;
  padding: 16px 32px 16px 32px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}

.colaboradores {
  padding: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.table-options {
  display: flex;
  gap: 8px;
  background: #eff0f2;
  border-radius: 16px 16px 0 0;
  padding-left: 25px;
}

.table-options-reports {
  display: flex;
  background: #eff0f2;
  border-radius: 16px 16px 0 0;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .table-options-separate {
    justify-content: space-evenly !important;
  }

  .table-options-reports {
    display: block;
  }

  .option-group {
    display: block;
  }

  .data-table-container td.sticky {
    position: initial !important;
  }

  .data-table-container th.sticky {
    position: initial !important;
  }
  
  .data-table-container th.sticky-second,
  .data-table-container td.sticky-second {
    position: initial !important;
  }
  
  .data-table-container th.sticky-third {
    position: initial !important;
  }
  
  .data-table-container td.sticky-third {
    position: initial !important;
  }
}

.table-options-reports p {
  margin-left: 7px;
  font-size: 13px;
  font-weight: bold;
  color: #7f888d;
}

.option-group {
  display: flex;
  gap: 10px;
}

.table-options-separate {
  display: flex;
  column-gap: 12px;
  background: #eff0f2;
  border-radius: 16px 16px 0 0;
}

.table-options-separate p {
  margin-left: 7px;
  font-size: 13px;
  font-weight: bold;
  color: #7f888d;
}

.option {
  display: flex;
  /*margin-right: 45px;*/
  cursor: pointer;
}

.option img {
  object-fit: contain;
  width: 12px;

}

.option-grupos {
  display: flex;
  margin-right: 20px;
  cursor: pointer;
}

.option-grupos img {
  object-fit: contain;
  width: 12px;

}

.table-options p {
  margin-left: 7px;
  font-size: 13px;
  font-weight: bold;
  color: #7f888d;
}

.option-download {
  background-color: #652d89;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  padding: 1px;
  width: 150px;
  height: 35px;
}

.option-download p {
  font-size: 13px;
  color: #ffffff;
}

.option-download img {
  object-fit: contain;
  filter: brightness(0) invert(1);
  
}

.option-filtrar {
  background-color: #652d89;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-right: 8px;*/
  margin-top: 3px;
  padding: 1px;
  width: 150px;
  height: 35px;
  /*margin-left: 20px;*/
}

.option-filtrar p {
  font-size: 13px;
  color: #ffffff;
}

.option-filtrar img {
  object-fit: contain;
  filter: brightness(0) invert(1);
  width: 12px;

}

.option-restaurar {
  background-color: #652d89;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  width: auto;
  height: 33px;
  margin-top: 3px;
}

.option-restaurar p {
  padding: 8px;
  margin: 0;
  font-size: 13px;
  color: #ffffff;
}

.option-restaurar img {
  margin: 5px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.table-container {
  overflow: auto;
}

.data-table-container {
  border-spacing: 0px;
  width: 100%;
  white-space: nowrap;
  table-layout: auto;
}

.data-table tr {
  position: relative;
  z-index: 0;
  padding: 8px;
}

.name-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.table-header {
  background-color: #ffffff;
}

.table-header td {
  color: #084969;
  font-weight: bold;
  padding: 8px;
}

.table-header tr {
  position: relative;
  z-index: 2;
}

.table-header tr th {
  position: sticky;
  top: 0;
  background: #ffffff;
  padding: 5px 5px;
  color: #084969;
}

.groups-header tr th {
  padding: 5px 10px;
}

thead.table-header tr th:first-child {
  border-left: 1px solid #eff0f2;
  top: 0;
  z-index: 2;
  padding: 5px 30px;
}

thead.table-header tr th:nth-child(2) {
  top: 0;
  z-index: 2;
}

.table-header tr th:last-child {
  border-right: 1px solid #eff0f2;
}

.table-header tr th:first-child,
.data-table td:first-child,
.table-header tr th:nth-child(2),
.data-table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #ffffff;
  z-index: 1;
}

.table-header tr th.thead-start,
.data-table td.td-start {
  position: static;
  background-color: #ffffff;
}

.table-header tr th.thead,
.data-table td.td {
  position: sticky;
  /* left: 220.91px; */
  min-width: 75px;
  background-color: #ffffff;
  z-index: 1;
}

.data-table-container th.sticky {
  position: sticky;
  top: 0;
  left: 0;
  min-width: 50px;
  background-color: white;
  z-index: 10;
}

.data-table-container td.sticky {
  position: sticky;
  top: 0;
  left: 0;
  min-width: 50px;
  background-color: white;
  z-index: 2!;
}

.data-table-container th.sticky-second,
.data-table-container td.sticky-second {
  position: sticky;
  top: 0;
  left: 127px !important;

  background-color: white;
  z-index: 9 !;
}

.data-table-container th.sticky-third {
  position: sticky;
  top: 0;
  left: 216.5px;
  background-color: white;
  z-index: 8;
}

.data-table-container td.sticky-third {
  position: sticky;
  top: 0;
  left: 216.5px;
  background-color: white;
  z-index: 8!;
}



.table-header tr th.colabs-header,
.data-table td.td-2 {
  position: sticky;
  min-width: 275px;
  max-width: 275px;
  left: 0;
  background-color: #ffffff;
  z-index: 1;
}

thead.table-header tr th.colabs-header-2,
.data-table td.colabs-table-2 {
  position: sticky;
  left: 120.91px;
  background-color: #ffffff;
  z-index: 1;
}

thead.table-header tr th.colabs-header,
.data-table td.name-container.colabs-table {
  position: sticky;
  left: 120.91px;
  background-color: #ffffff;
  z-index: 1;
}

.progress-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.progress-bar-background {
  height: 4px;
  width: 100px;
  background-color: #f3f3f3;
  border-radius: 50px;
}

.progress-bar-foreground {
  height: 100%;
  background-color: #00ae9e;
  border-radius: inherit;
}

.progress-text {
  color: gray;
  font-size: 14px;
}

.data-table {
  background-color: #ffffff;
}

.data-table td {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  border: 1px solid #eff0f2;
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
}

.edit-logo {
  cursor: pointer;
}

.error-text {
  position: absolute;
  top: 71%;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  font-size: 16.5px;
  font-weight: bold;
  width: 500px;
}

.error-input {
  background-color: #ffcccc;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  border-radius: 0 0 16px 16px;
  padding: 5px 15px 5px 0;
}

.row-numbers {
  display: flex;
  margin: 0 20px;
}

.row-numbers p {
  margin: 0;
  font-size: 14px;
  color: #444343;
  margin-right: 10px;
}

.row-numbers select {
  color: #444343;
  border: none;
}

.row-numbers select:focus {
  outline: none;
}

.pagination p {
  margin: 0;
  font-size: 14px;
  color: #444343;
}

.pagination {
  display: flex;
}

.table-options-init {
  width: 100%;
    display: flex;
    overflow: auto;
align-items: center;
gap: 8px
}

.table-options-groups {
  justify-content: space-between;
}

.option-descargar {
  /*margin-right: 25px;*/
  display: flex;
  align-items: center;
}
.option-descargar img {
  width: 14px;
}
.menu-image {
  width: 100%;
  height: 99%;
}

.sede-selector-container {
  display: flex;
  justify-content: space-between;
  min-width: 53px;
  max-width: 84px;
  /* background-color: rgba(207, 210, 211, 0.16);
  border-left: 1px solid #eff0f2; */
  position: initial;
  /* left: 0; */
  z-index: 1;
}

.sede-selector-container p {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700;
}

.three-dots {
  height: 15px;
  width: 15px;
  margin-top: -2px;
  color: lightgray;
  margin-right: -75px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  min-width: 180px;
  padding: 2px 5px;
  z-index: 2;
  top: 19px;

  div {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: -1px;
    cursor: pointer;

    p {
      text-align: center;
    }

    img {
      align-self: center;
    }
  }

  div {
    transition: background-color 0.3s;
  }

  div:hover {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
  }

  div:hover p {
    color: rgb(76, 5, 97);
    font-weight: bold;
  }

  div:nth-child(odd) {
    border-bottom: 1px solid lightgray;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
  right: -78px;
}

.edit-sede {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.edit-sede img {
  align-self: center;
}

.edit-sede p {
  padding: 8px 10px;
  font-size: 13px;
  font-weight: 700;
  color: #7f888d;
}

/*  */
.edit-button-container {
  position: relative;
  margin-left: 10px;
  text-align: right;
  width: 100%;
  /* Asegura que el contenedor ocupe el ancho completo disponible */
  padding-left: 5px;
}

/*  */

.pagination-arrow {
  display: flex;
  align-items: center;
}

.pagination-arrow img {
  margin: 0 5px;
  cursor: pointer;
}

.fecha-grupos {
  position: relative;
  display: flex;
  gap: 32px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.fecha-grupos label {
  position: absolute;
  font-size: 12px;
  color: #bdbdbd;
  z-index: 3;
  top: -8px;
  left: 16px;
  background-color: white;
}

.dates {
  display: flex;
  gap: 32px;
}

.fecha-grupos a {
  margin-bottom: auto;
  margin-top: auto;
  color: #7f888d;
  cursor: pointer;
  padding-bottom: 4px;
}

.active-date {
  font-weight: 600;
  color: black !important;
  border-bottom: 2px solid purple;
}

.picker {
  padding: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  color: #7f888d;
  text-transform: capitalize;
}

div.react-datepicker-popper {
  z-index: 3;
}

.update-file-response-container {
  position: relative;
  margin-top: 20px;
  /* Ajusta el valor según sea necesario para que se adapte a tu diseño */
  border-radius: 5px;
  font-weight: 500;
  background-color: #c5f3f1;
  width: 100%;
  padding-bottom: 48px;
  margin-bottom: 24px;
  z-index: 10;
}

.update-file-response-container-success {
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  background-color: #c5f3f1;
  z-index: 10;
  width: 100%;
  padding-bottom: 48px;
  margin-bottom: 24px;

  .update-file-message {
    padding-left: 24px;
    padding-right: 24px;

    img:first-child() {
      margin-right: 44px;
    }

    p {
      font-size: 16px;
    }
  }

  .close-alert {
    margin-right: 44px;
  }
}

.update-file-response-container-error {
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  background-color: #fccfda;
  z-index: 10;
  width: 100%;
  padding-bottom: 48px;
  margin-bottom: 8px;

  .update-file-message {
    padding-left: 24px;
    padding-right: 24px;

    img:first-child() {
      margin-right: 44px;
    }

    p {
      font-size: 16px;
    }
  }

  .close-alert {
    margin-right: 44px;
  }
}

.update-file-response-container-info {
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  background-color: #e1e2e3;
  width: 100%;
  padding-bottom: 48px;
  margin-bottom: 24px;
  z-index: 10;

  .update-file-message {
    padding-left: 24px;
    padding-right: 24px;

    img:first-child() {
      margin-right: 44px;
    }

    p {
      font-size: 16px;
    }
  }

  .close-alert {
    margin-right: 44px;
  }
}

.grupos-alert {
  bottom: 95px;
}

.update-file-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  border-radius: 8px;
}

.update-file-message img {
  margin: 10px;
}

.update-file-message p {
  margin: 0;
  text-align: left;
  flex-grow: 1;
  color: #5e6a71;
  font-size: 14px;
}

.loading-data {
  background-color: #bdbdbd;
}

.close-alert {
  cursor: pointer;
}

.table-header-sedes tr th {
  position: sticky;
  top: 0;
  background: #ffffff;
  padding: 8px 8px;
  color: #006990;
  z-index: 1;
}

.table-header-sedes tr th:first-child,
.data-table-sedes td:first-child {
  position: sticky;
  left: 0;
}

.table-header-sedes tr th:first-child {
  z-index: 2;
  border-left: 1px solid #eff0f2;
}

.table-header-sedes tr th:last-child {
  border-right: 1px solid #eff0f2;
}

.data-table-sedes td {
  border: 1px solid #eff0f2;
  padding: 10px 20px;
  background-color: #ffffff;
}

/* CSS */
.flexRowSpaceBetween {
  display: flex;
  flex-direction: row;
  width: 94%;
  padding-left: 1.6rem;
  padding-top: 16px;
  padding-right: 20px;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  /* Desplazamiento en X, Y, difuminado, extensión y color */
  transition: box-shadow 0.3s;
  /* Para un efecto suave al cambiar de estado, por ejemplo, al pasar el mouse */
}

.flexRowSpaceBetween_Formadores {
  display: flex;
  flex-direction: row;
  width: 95.5%;
  padding-left: 1.6rem;
  padding-top: 16px;
  padding-right: 20px;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.flexColumnLeft {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.pBoldBlack {
  margin: 0;
  margin-inline: -15px;
  color: #363c3f;
  font-weight: 600;
  font-size: 15px;
}

.pBoldBlue {
  margin: 0;
  font-weight: 700;
  color: #006990;
  margin-inline: -15px;
}

.spanBoldBlack {
  color: #363c3f;
  font-weight: 700;
}

.flexRowGap14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.flexRowGrey {
  display: flex;
  flex-direction: row;
  gap: 24px;
  font-weight: 700;
  color: #7f888d;
}

.imgPointer {
  display: flex;
  align-items: center;
  gap: 16px;
  object-fit: contain;
  cursor: pointer;
}

.card-button {
  background-color: #652d89;
  border-radius: 5px;
  color: white;
  padding: 9px;
  margin-right: 5px;
  width: 94px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.grid-container {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 0.1rem;
  padding-right: 1.4rem;
  gap: 1.3rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  border: 0.5px solid #e5e5e5;
  padding: 1rem;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.flex-row-space-between-gap {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}

.hidden-datepicker {
  visibility: hidden;
  position: absolute;
}

.date-picker-container {
  position: relative;
}

.date-picker-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 100%;
}

.select-week-button {
  margin-bottom: 10px;
}

.text-left {
  text-align: left;
}

.progress-container {
  display: flow;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-container-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden; 
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 5px;
  border-radius: 10px;
  transition: width 0.4s ease;
  background-clip: text;
}

.progress-percentage {
  color: white;
  font-weight: bold;
  z-index: 1 !;
  margin-right: 2px;
}

.colabs-table-id {
  width: 5%;
  border-right: none !important;
}

.colabs-table-nomina {
  border-left: none !important;
}

.colabs-filter-header-modal {
  margin-bottom: 20px;
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #652d89;
  justify-content: space-between;
}

.colabs-filter-header-modal p {
  font-size: 18px;
  font-weight: bold;
}

.colabs-filter-header-modal img {
  margin-top: 10px;
  margin-right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.colabs-filter-modal .MuiFormControl-root {
  margin-bottom: 20px;
  /* Añade espacio debajo de cada select */
  width: 75%;
}

.filter-buttons-modal {
  display: inline-flex;
  justify-content: center;
  column-gap: 6px;
  row-gap: 6px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cancel-button-modal {
  height: 50px;
  width: 150px;
  cursor: pointer;
  border-radius: 8px;
  /* Hace que los botones ocupen todo el espacio disponible */
  margin: 0 5px !important;
  border: 1px solid #652d89 !important;
  /* Agrega tu borde aquí */
  background-color: white !important;
  /* Fondo blanco por defecto */
  color: #652d89 !important;
  /* Color de texto por defecto */
  /* Añade aquí más estilos si necesitas */
  font-weight: bold !important;
}

.cancel-button-modal:hover {
  background-color: #652d89 !important;
  /* Fondo morado al hacer hover */
  color: white !important;
  /* Texto blanco al hacer hover */
}

.accept-button-modal {
  height: 50px;
  width: 150px;
  cursor: pointer;
  border-radius: 8px;
  /* Hace que los botones ocupen todo el espacio disponible */
  margin: 0 5px !important;
  border: 1px solid #652d89 !important;
  /* Agrega tu borde aquí */
  background-color: white !important;
  /* Fondo blanco por defecto */
  color: #652d89 !important;
  /* Color de texto por defecto */
  /* Añade aquí más estilos si necesitas */
  font-weight: bold !important;
}

.accept-button-modal:hover {
  background-color: #652d89 !important;
  /* Fondo morado al hacer hover */
  color: white !important;
  /* Texto blanco al hacer hover */
}

.custom-autocomplete-option {
  /* Aplica cualquier estilo base que necesites para las opciones aquí.
     Asegúrate de no cambiar estas propiedades en el hover para mantener la consistencia. */
  padding: 10px 15px;
  /* Ejemplo, ajusta según tus estilos actuales */
  margin: 0;
  font-size: inherit;
  /* Utiliza 'inherit' para mantener el tamaño de fuente del contenedor */
  font-weight: normal;
  /* Asegúrate de que el peso de la fuente no cambie al hacer hover */
}

.custom-autocomplete-option:hover {
  background-color: purple !important;
  /* Fondo morado al hacer hover */
  color: white !important;
  /* Texto blanco */
}
