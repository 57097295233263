@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.container {
  background-color: rgba(207, 210, 211, 0.16);
  width: 100%;
  padding-top: 15px;
  padding-right: 35px;
  padding-bottom: 25px;
  padding-left: 19px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
}

.pagination-arrow {
  display: flex;
  align-items: center;
}

.pagination-arrow img {
  margin: 0 5px;
  cursor: pointer;
}

.principal {
  background-color: #ffffff;
  padding: 7px;
  padding-left: 1.1rem;
  padding-bottom: 20px;
  margin: 5px auto;
  width: 100%;
  min-height: auto;
  box-sizing: border-box;
  border-radius: 15px;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.gridContainer {
 margin-top: 48px;
}

.gridItem {
  background-color: #ffffff;
  border: 2px solid #000000;
  padding: 3.5rem;
  border-radius: 10px;
  width: calc(100% - 120px);
  justify-content: center;
  align-items: center;
  height: 35px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 19.5px;
  margin-bottom: 19.5px;
  margin-left: 0px;
  position: relative;
}

.gridItem2 {
  background-color: #ffffff;
  border: 2px solid #000000;
  padding: 3.5rem;
  border-radius: 10px;
  display: flex; 
  flex-direction: column;
  align-items: center;
  height: 85px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 80px;

  position: relative;
}

@media (min-width: 576px) {
  .gridItem2 { height: 125px; }
}
@media (min-width: 768px) {
  .gridItem2 { height: 125px; }
}
@media (min-width: 992px) {
  .gridItem2 { height: 95px; }
}
@media (min-width: 1200px) {
  .gridItem2 { height: 65px; }
}
@media (min-width: 1400px) {
  .gridItem2 { height: 55px; }
}

.gridItemEmpty {
  justify-content: center;
  width: 100%;
  height: 250px;
  text-align: center;
  color: #666;
  border: 1px solid #9c9c9c;
  margin: 15px;
}

.itemWrapper {
  margin-top: 50px;
  display: flex;
  align-items: left;
}

.arrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: -10px;
  margin-right: 50px;
  margin-left: 0px;
  width: 1px;
  height: 2px;
}

.arrowIcon {
  position: absolute;
  width: 20px;
  height: auto;
  margin-left: 100%;
  top: calc(50% - 30px);
  right: -20px;
}

.flexColumnLeft {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .flexColumnLeft {
    display: block !important;
  }

 
}

.borderRight {
  border-right: 1px solid #c6c6c693;
  padding-right: -4px;
  margin-right: -20px;
}

.scrollable {
  overflow-y: auto;
  overflow-x: auto;
}

@media (max-width: 796px) {
  

}

.scrollable::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}


.scrollable::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.card {
  max-height: 100%;
  display: flow;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.cardColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 4;
}

.flexRowLeftAlign {
  margin: 3px 10px;
}

.sidebar {
  width: 23%;
  text-align: left;
  margin-top: 16px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  max-height: 50vh;
  padding-bottom: 16%;
  overflow-y: auto;
}

.flexColumnLeft {
  display: flex;
  gap: 4px;
  text-align: left;
}

.pBoldBlack {
  color: #363c3f;
  font-weight: 650;
  text-align: left;
  font-size: 16px;
  /*line-height: 10px;*/
}

.progressContainer {
  width: 95%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  top: 5px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: '100%';
}

.progressBar {
  height: 8px;
  border-radius: 5px;
  text-align: right;
  overflow: hidden;
  position: relative;
}

.progressPercentage {
  left: 47%;
  top: -14%;
  line-height: 10px;
  color: rgb(38, 38, 38);
  margin-left: 10px;
  overflow: hidden;
  position: relative;
}

.ppuesto {
  font-weight: bold;
  color: rgb(64, 111, 143);
  font-size: 13px;
}

.ppuestolevel {
  font-weight: bold;
  color: rgb(11, 11, 11);
  font-size: 16px;
}

.pos {
  font-weight: bold;
  color: rgb(11, 11, 11);
  font-size: 15px;
}

.valorOs {
  color: #8f1077;
  font-size: 14px;
}

.valorFechaFin {
  color: #8f1077;
  padding-left: 2px;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 38%;
  margin: auto;
}

.flexRowCenter-Left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: 180px;
  gap: 23px;
  width: 18%;
  margin-left: auto;
  margin-right: 0;
}

.imgPointer {
  margin-top: -0.1rem;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  gap: 2px;
  object-fit: contain;
  cursor: pointer;
  font-weight: 650;
  color: #7f888d;
  justify-content: center;
}

.starIcon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 44px;
  height: 50px;
  z-index: 1;
}

.stariIcon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 44px;
  height: 50px;
  z-index: 1;
}

.cursoNombre {
  font-size: 72%;
  color: #333;
  margin-top: -10px;
  text-align: center;
  font-weight: 799;
  margin-bottom: 8px;

}

.cursoFechaInicio {
  font-size: 70%;
  color: #666;
  margin-top: -8px;
  margin-bottom: 5px;
}

.cursoEstado {
  font-size: 12px;
  margin-top: -1px;
  padding: 4px 8px;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center; /* Alinea los elementos verticalmente */
  justify-content: flex-start; /* Alinea los elementos horizontalmente */
}

.cursoEstadoCompletado {
  background-color: #4caf50;
  color: #ffffff;
}

.cursoEstadoNoCompletado {
  background-color: #e80e0e;
  color: #ffffff;
}

.cursoEstadoSinAsignar {
  background-color: #b51106;
  color: #ffffff;
}