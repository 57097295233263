.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center; 
  z-index: 9;
}

.modal {
  position: fixed;
  top: 60px;
  right: 15px;
  width: 302px;
  border-radius: 10px;
  z-index: 10;
  overflow-x: hidden;
  scrollbar-color: transparent !important;
  background: #fff;
}

.modalContent {
  max-height: calc(88vh - 44px);
  overflow-y: auto; 
  margin-top: 0;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(54, 60, 63, 1);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.04em;
  margin-bottom: 0
}

.closeButton {
  background-color: transparent;
  width: 18px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.closeButton img {
  display: block;
  width: 200%;
  height: auto;
}

.message {
  display: flex;
  align-items: flex-start;
  padding: 12px 20px;
  gap: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.divider {
  width: 303px;
  height: 2px;
  background-color: #dad1d1; 
  margin: 1 auto; 
}
.messageIcon, .mailIcon {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
}

.messageInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  flex: 1;
}

.messageInfo p {
  margin: 0;
  line-height: 1.5;
}

.messageInfo span {
  display: block;
  color: #666;
  font-size: 0.8em;
  margin-top: 4px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: -8px;
  object-fit: contain; 
}

.conteoInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  flex: 1;
  margin-top: -20px;
}

.conteoInfo p, .conteoInfo span {
  margin: 0;
  line-height: 3;
  display: block;
  color: #666;
  font-size: 0.8em;
  margin-top: 4px;
  margin-left: 20px;
}
.messageUnread {
  background-color: #f0f0f0; /* Gris opaco */
  color: #686868; /* Texto más oscuro para mejorar el contraste */
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.modal-enter {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.scrollable {
  max-height: 800px;
  overflow-y: auto;
  overflow-x: auto;
}

.scrollable::-webkit-scrollbar {
  width: 9px; /* Ancho de la barra de desplazamiento vertical */
  height: 9px; /* Altura de la barra de desplazamiento horizontal */
}

/* Estiliza el "track" de la barra de desplazamiento */
.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Estiliza el "thumb" de la barra de desplazamiento */
.scrollable::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}