.breadcrumb {
  display: flex;
  margin-bottom: 10px;
  margin-left: 5px;
  column-gap: 12px;
}

.breadcrumb a {
  text-decoration: none;
  color: #652D89;
}

.crumb {
  color: #ada9a9;
}


.last {
  color: #ada9a9;
}