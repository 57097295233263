div.css-b62m3t-container {
  width: 100%;
}

div.css-1dimb5e-singleValue {
  text-align: left;
}

div.css-1jqq78o-placeholder {
  text-align: left;
}

div.css-13cymwt-control {
  border-color: #afb4b8;
}
