/* .App {
  text-align: center;
} */

.container-carousel{
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.content-container {
  display: flex;
  height: 90vh;
}

.menu {
  width: 25%;
}


/* responsive styles */
.bootstrap-wrapper .hidden-xs-up {
  display: none !important;
}

@media (max-width: 575.98px) {
  .bootstrap-wrapper .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .bootstrap-wrapper .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .bootstrap-wrapper .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 850px) {
  .bootstrap-wrapper .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 850px) {
  .bootstrap-wrapper .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .bootstrap-wrapper .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .bootstrap-wrapper .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .bootstrap-wrapper .hidden-xl-up {
    display: none !important;
  }
}

.bootstrap-wrapper .hidden-xl-down {
  display: none !important;
}