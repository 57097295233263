.edit-option {
  margin-bottom: 20px;
}

p.edit-colab-info {
  margin: 10px 0 10px 15px;
}

.colab-info {
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
}

p.colab-name {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #363c3f;
}

p.colab-location {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: #363c3f;
}

.direccion-selector {
  display: flex;
  position: relative;
  margin: 0 15px;
  border-radius: 5px;
}

.direccion-selector p {
  margin: 0;
  position: absolute;
  bottom: 25px;
  left: 15px;
  font-size: 13px;
  font-weight: normal;
  color: #bfc3c6;
  background-color: #ffffff;
  padding: 0 3px;
}

.direccion-selector select {
  width: 100%;
  border: none;
  color: #5e6a71;
  font-size: 15px;
}

.direccion-selector select:focus {
  outline: none;
}


.direccion-selector input {
  width: 100%;
  border: none;
  color: #5e6a71;
  font-size: 15px;
  padding-left: 5px;
}

.subdireccion-selector {
  margin-top: 20px;
}

.asistencia-options {
  margin: 0 15px;
  display: flex;
  align-items: center;
}

.asistencia-options label {
  display: flex;
  text-align: left;
  width: 33%;
  color: #5e6a71;
  font-size: 14px;
}

.asistencia-options label input {
  margin-right: 10px;
}

.hospedaje {
  margin: 15px;
}

.hospedaje p {
  text-align: left;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #363c3f;
}

.hospedaje-options {
  display: flex;
  text-align: left;
  margin-top: 10px;
}

.hospedaje-options label {
  display: flex;
  width: 50%;
  align-items: center;
  font-size: 14px;
  color: #5e6a71;
}

.hospedaje-options label input {
  margin: 0 5px 0 0;
}

.noches-container {
  border: 1px solid #afb4b8;
  padding: 6px;
}

.noches-input {
  width: 100%;
  border: none;
  color: #5e6a71;
  font-size: 15px;
  padding-left: 5px;
}

.noches-input:focus {
  outline: none;
}
