.menu {
  width: 20vw;
  @media (max-width: 776px) {
    width: 30vw;
  }
}

@media (max-width: 776px) {
  .menu {
    width: 80%;
  }

  .menu-list li a {
    color: white !important;
  }
}

.menu-list {
  list-style: none;
  padding: 0;
  text-align: justify;
  width: 100%;
}

.menu-list li {
  display: flex;
  padding: 0 20px;
}

.menu-list li a {
  width: 100%;
  text-decoration: none;
  color: #5e6a71;
  padding: 10px;
  display: flex;
  margin-left: 5px;

}
.menu-list li a > span{
  margin-left: 1px
}
.menu-list li a.active > span{
  margin-left: -2px
}
.menu-list li a.active {
  width: 100%;
  background: #652d89;
  color: #ffffff;
  border-radius: 5px;
}

.icon {
  display: flex;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
}

.people-icon {
  background-image: url(../../assets/people.svg);
}

.menu-list li a.active .people-icon {
  background-image: url(../../assets/people-white.svg);
}

.leaders-icon {
  background-image: url(../../assets/star.svg);
}

.menu-list li a.active .leaders-icon {
  background-image: url(../../assets/star-white.svg);
}

.calendar-icon {
  background-image: url(../../assets/calendar.svg);
}

.menu-list li a.active .calendar-icon {
  background-image: url(../../assets/calendar-white.svg);
}

.groups-icon {
  background-image: url(../../assets/groups.svg);
}

.menu-list li a.active .groups-icon {
  background-image: url(../../assets/groups-white.svg);
}

.headquarter-icon {
  background-image: url(../../assets/headquarter.svg);
}

.menu-list li a.active .headquarter-icon {
  background-image: url(../../assets/headquarter-white.svg);
}

.report-icon {
  background-image: url(../../assets/report.svg);
}

.menu-list li a.active .report-icon {
  background-image: url(../../assets/report-white.svg);
}

.yastas-icon {
  background-image: url(../../assets/yastas.svg);
}

.menu-list li a.active .yastas-icon {
  background-image: url(../../assets/yastas-white.svg);
}

.menu-collapsed .menu-list li a.active .icon {
  margin-right: 0;
  flex-grow: 1;
}

.menu-collapsed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-collapsed .arrow {
  height: 12px;
  width: 12px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 6px;
}

.menu-collapsed .arrow.active {
  transform: rotate(180deg);
  margin-right: 6px;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  margin: 16px;
}

.content ul {
  padding-left: 10px !important;
}

.group-active {
  background: white;
  color: #7200bb !important;
  font-weight: 600;
}
.menu-list li a.group-active {
  color: #7200bb !important;
}
.content img {
  height: 8px;
  width: 8px;
}

.content span {
  display: flex;
  flex-direction: row;

  gap: 8px;

  align-items: center;
  justify-content: space-between;
}

.group-active span img {
  color: #7200bb !important;
}

.stroke {
  height: 6px;
  width: 6px;
  color: black;
}

.stroke.active {
  color: #7200bb !important;
}

.menu-item-with-submenu {
  position: relative;
  padding-right: 20px;
  /* Asegúrate de tener suficiente espacio para la flecha */
}

.arrow {
  /* Ajusta la posición y tamaño de tu flecha */
  height: 12px;
  width: 12px;
  transition: transform 0.3s ease;
}

.menu-item-with-submenu .arrow {
  /* Ajusta la posición para alinear con tu diseño */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.menu-item-with-submenu .arrow.active {
  /* Rota la flecha para arriba cuando el menú está expandido */
  transform: translateY(-50%) rotate(-180deg);
}

.menu-item.submenu-active {
  color: #652d89;
  /* o el color que quieras usar */
}

.menu-collapsed .menu-item-with-submenu.active {
  background-color: #652d89;
  /* Reemplaza con el color que corresponda */
}

.submenu-list {
  padding-left: 10px;
  /* Puedes ajustar este valor si es necesario */
}

.submenu-list li {
  /* height: 45px;
  margin-bottom: 10px; */
  display: flex;
  /* Usa flexbox para la alineación */
  align-items: center;
  /* Centra los elementos verticalmente */
}

.submenu-list li a::before {
  content: '•';
  color: black;
  font-size: 1.5em;
  /* Ajusta el tamaño del punto según lo necesites */
  margin-right: 10px;
  /* Espacio entre el punto y el texto */
  /* vertical-align: middle; Ya no es necesario con flexbox */
  transition: color 0.3s ease;
}

.submenu-list li a {
  display: flex;
  /* Usa flexbox para la alineación */
  align-items: center;
  /* Centra los elementos verticalmente */
}

.submenu-list li a.group-active::before {
  color: #652d89;
  /* Color del punto cuando el enlace está activo */
}
