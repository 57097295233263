.table-options-reports {
  display: flex;
  background: #eff0f2;
  border-radius: 16px 16px 0 0;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px;
}

.action-buttons-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}

.action-buttons-container .action-button {
  background-color: #652d89;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 33px;
  margin-top: 3px;
  padding:2px  8px;
  
}

.action-buttons-container .action-button p {
  margin: 0;
  font-size: 13px;
  color: #ffffff;
}

.action-buttons-container .action-button img {
  margin: 5px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.action-buttons-container .left {
  display: flex;
  width: 80%;
  gap: 8px;
  flex-wrap: wrap;
}

.load-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 16px;
}

@media (max-width: 768px) {
  /* Estilos para dispositivos móviles */
  .action-buttons-container .action-button p {
    padding: 0;
  }
  .action-buttons-container .left {
    width: auto;
  }
}
